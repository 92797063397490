window.onload = () => {
	initMainNavHover();
	initMainMobileNav();
	initSliders();
	initSecondaryMainHeroVideo();
};

const initSecondaryMainHeroVideo = () => {
	const secondaryMainHeroVideo = document.querySelector('.hero--main-secondary video');
	if (secondaryMainHeroVideo) {
		secondaryMainHeroVideo.play();
	}
}

const initSliders = () => {
	const sliders = document.getElementsByClassName('splide');
	for (let i = 0; i < sliders.length; i++) {
		new Splide(sliders[i]).mount();
	}
}

const mobileOffCanvas = document.querySelector('.mobile-offcanvas');
let mobileNavIsActive = false;
window.toggleMobileNav = () => {
	if (mobileNavIsActive) {
		mobileOffCanvas.classList.remove('active');
		mobileNavIsActive = false;
	} else {
		mobileOffCanvas.classList.add('active');
		mobileNavIsActive = true;
	}
}

const mobileNavBarObserver = new ResizeObserver(entries => {
	for (let entry of entries) {
		if (entry.contentRect.width > 768 && mobileNavIsActive) {
			mobileOffCanvas.classList.remove('active');
			mobileNavIsActive = false;
		}
	}
});
mobileNavBarObserver.observe(mobileOffCanvas);

let isDropdownActive = false;

const initMainNavHover = () => {
	const navItemsWithDropdowns = document.querySelectorAll('.main-nav .nav-item[data-dropdown-id]');
	const mainNavBar = document.querySelector('.main-nav');
	const mainNavBarDropdowns = document.querySelectorAll('.main-nav__dropdown');
	navItemsWithDropdowns.forEach(item => {
		item.addEventListener('mouseenter', () => {
			let activeDropdown = document.querySelector('.main-nav__dropdown.active');
			if (activeDropdown) activeDropdown.classList.remove('active');
			mainNavBarDropdowns[item.dataset.dropdownId].classList.add('active');
			mainNavBar.classList.add('hovered')
			isDropdownActive = true;
		});
		mainNavBar.addEventListener('mouseleave', () => {
			if (isDropdownActive) {
				mainNavBar.classList.remove('hovered');
				document.querySelector('.main-nav__dropdown.active').classList.remove('active');
				isDropdownActive = false;
			}
		})
	})
};

const initMainMobileNav = () => {
	const mobileOffCanvas = document.querySelector('.mobile-offcanvas__content');
	const forwardNavigations = mobileOffCanvas.querySelectorAll('.nav-item--forward');

	forwardNavigations.forEach(navItem => {
		navItem.querySelector('.nav-item__link').addEventListener("click", (e) => {
			e.preventDefault();
			if (navItem.classList.contains('active')) {
				navItem.classList.replace('nav-item--back', 'nav-item--forward');
			} else {
				navItem.classList.replace('nav-item--forward', 'nav-item--back');
			}
			mobileOffCanvas.classList.toggle('active-submenu');
			navItem.classList.toggle('active');
		})
	})
}

const selectElements = document.querySelectorAll('select');

selectElements.forEach(element => {
	element.addEventListener('focus', (e) => {
		e.target.parentNode.classList.add('active');
	})
	element.addEventListener('blur', (e) => {
		e.target.parentNode.classList.remove('active');
	})
	element.addEventListener('change', (e) => {
		e.target.parentNode.classList.remove('active');
	})
});

const accordionElements = document.querySelectorAll('.accordion');
const accordionEvents = ['focus', 'click', 'blur'];

accordionElements.forEach(function (element) {
	accordionEvents.forEach(function (accordionEvent) {
		element.addEventListener((accordionEvent), function () {
			element.classList.toggle('accordion--open');
		})
	})
});

const menu = document.querySelector(".menu");
let menuItems = [];
if (menu) menuItems = menu.querySelectorAll('.menu__item--toggle');


menuItems.forEach(function (item) {
	item.addEventListener('click', function () {
		item.classList.toggle('menu__item--open');
	})
})

const heroWithMenu = document.querySelector('.block--resources-support .hero');

if (heroWithMenu) {
	const heroMenu = heroWithMenu.querySelector('.hero-menu');
	const heroSearch = heroWithMenu.querySelector('.hero__search');
	const heroContent = heroWithMenu.querySelector('.card__content');
	const heroMenuToggle = heroWithMenu.querySelector('.hero__menu-toggle');
	heroMenuToggle.addEventListener("click", () => {
		heroMenu.classList.toggle('active');
		heroMenuToggle.classList.toggle('active');
	})
	const resizeObserver = new ResizeObserver(entries => {
		for (let entry of entries) {
			if (window.innerWidth < 768) {
				heroMenu.prepend(heroSearch);
			} else {
				heroMenu.classList.remove('active');
				heroMenuToggle.classList.remove('active');
				heroContent.appendChild(heroSearch);
			}
		}
	});
	resizeObserver.observe(heroWithMenu);
}

function getCountryCodeFromLocalStorage() {
    return localStorage.getItem('userCountryCode');
}

function setCountryCodeToLocalStorage(countryCode) {
    localStorage.setItem('userCountryCode', countryCode);
}

document.addEventListener('DOMContentLoaded', function() {
	var currencyMap = {
		'US': { '€': '$', '£': '$' },
		'CA': { '€': '$', '£': '$' },
		'GB': { '€': '£', '$': '£' },
		'default': { '€': '€', '$': '€', '£': '€' } // Default to Euro
	};

	var countryCode = getCountryCodeFromLocalStorage();

	if (countryCode) {
		replaceWrappedCurrencySymbols(countryCode);
		replaceCurrencySymbolsInSelectOptions(countryCode);
	} else {
		var ipinfoToken = '913537d6e17afa';
		// Fetch country code via ipinfo.io API
		fetch('https://ipinfo.io?token=' + ipinfoToken)
		.then(response => response.json())
		.then(data => {
			if (data && data.country) {
				setCountryCodeToLocalStorage(data.country);
				replaceWrappedCurrencySymbols(data.country);
				replaceCurrencySymbolsInSelectOptions(data.country);
			}
		})
		.catch(error => {
			console.error('Error fetching country code:', error);
			replaceWrappedCurrencySymbols('default');
			replaceCurrencySymbolsInSelectOptions('default');
		});
	}

	function replaceWrappedCurrencySymbols(countryCode) {

		var replacements = currencyMap[countryCode] || currencyMap['default'];

		// Find all wrapped currency symbols and replace them
		document.querySelectorAll('.cursym').forEach(function(elem) {
			var symbol = elem.getAttribute('data-symbol');
			var newSymbol = replacements[symbol] || symbol;
			elem.textContent = newSymbol;
		});

	}

	// Function to replace currency symbols in select option texts
	function replaceCurrencySymbolsInSelectOptions(countryCode) {

		var replacements = currencyMap[countryCode] || currencyMap['default'];

		document.querySelectorAll('select option').forEach(function(option) {
			var text = option.textContent;
			Object.keys(replacements).forEach(function(currencySymbol) {
				var regex = new RegExp('\\' + currencySymbol, 'g');
				text = text.replace(regex, replacements[currencySymbol]);
			});
			option.textContent = text;
		});
	}

});
